import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class HorizontalScroll {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    const horizontalScrollWrapper = this.element.querySelector(
      '.horizontal-scroll-wrapper'
    );

    const setHeight = () => {
      const totalScrollLength = horizontalScrollWrapper.scrollWidth;
      this.element.style.height = `${totalScrollLength}px`;
    };

    const createScrollAnimation = () => {
      const horizontalScrollLength =
        horizontalScrollWrapper.scrollWidth - window.innerWidth;

      gsap.to(horizontalScrollWrapper, {
        x: -horizontalScrollLength,
        ease: 'none',
        scrollTrigger: {
          trigger: this.element,
          start: 'top top',
          end: () => `+=${horizontalScrollWrapper.scrollWidth}`,
          scrub: true,
          pin: true,
          anticipatePin: 1,
          invalidateOnRefresh: true,
        },
      });
    };

    // Initialize
    setHeight();
    createScrollAnimation();

    // Refresh on resize
    window.addEventListener('resize', () => {
      setHeight();
      ScrollTrigger.refresh();
    });
  }
}

// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

// gsap.registerPlugin(ScrollTrigger);

// export default class HorizontalScroll {
//   constructor(element) {
//     this.element = element;
//     this.init();
//   }

//   init() {
//     const container = this.element;
//     const wrapper = container.querySelector('.horizontal-scroll-wrapper');

//     const updateScroll = () => {
//       const totalWidth = wrapper.scrollWidth;
//       const viewportWidth = window.innerWidth;
//       const scrollDistance = totalWidth - viewportWidth;

//       // Kill previous ScrollTrigger instance if it exists
//       if (this.scrollTrigger) {
//         this.scrollTrigger.kill();
//       }

//       // Create a new ScrollTrigger
//       this.scrollTrigger = ScrollTrigger.create({
//         trigger: container,
//         start: 'top top',
//         end: () => `+=${totalWidth}`,
//         scrub: true,
//         pin: true,
//         anticipatePin: 1,
//         invalidateOnRefresh: true,
//         animation: gsap.to(wrapper, {
//           x: -scrollDistance,
//           ease: 'none',
//           duration: 1,
//         }),
//       });
//     };

//     // Initial setup
//     updateScroll();

//     // Update on window resize
//     window.addEventListener('resize', updateScroll);
//   }
// }
