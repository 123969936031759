import ComponentFactory from "./ComponentFactory";
import Icons from "./utils/Icons";
// import { gsap } from "gsap";

class Main {
  constructor() {
    this.init();
  }

  init() {
    document.documentElement.classList.add("has-js");
    new ComponentFactory();
    Icons.load();
  }
}

// gsap.top(".box", {
//   duration: 3,
//   rotation: 360,
//   scale: 2,
// });

new Main();
