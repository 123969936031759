import Scrolly from './components/Scrolly';
import Carousel from './components/Carousel';
import Header from './components/Header';
// import Youtube from './components/Youtube';
import Form from './components/Form';
// import Tabs from './components/Tabs';
// import Accordion from './components/Accordion';
// import Accordion from "./components/Tabcarousel";
// import Customcursor from './components/Customcursor';
// import Menu from './components/Menu';
// import Bgcursor from './components/Bgcursor';
import HorizontalScroll from './components/HorizontalScroll';
import ImageTabs from './components/ImageTabs';
import NameAnimation from './components/NameAnimation';
import VideoPlayer from './components/VideoPlayer';
import CompetencesAnimation from './components/ScrollTrigger';

export default class ComponentFactory {
  constructor() {
    this.componentList = {
      Scrolly,
      Carousel,
      Header,
      // Youtube,
      Form,
      // Customcursor,
      ImageTabs,
      NameAnimation,
      // Accordion,
      // Pop,
      // Tabs,
      // Tabcarousel,
      // Menu,
      // Bgcursor,
      HorizontalScroll,
      VideoPlayer,
      CompetencesAnimation,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentName = element.dataset.component;

      if (this.componentList[componentName]) {
        new this.componentList[componentName](element);
      } else {
        console.log(`La composante ${componentName} n'existe pas!`);
      }
    }
  }
}
