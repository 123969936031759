export default class ImageTabs {
  constructor(element) {
    this.element = element;

    // Get all tab buttons and panels within this element
    this.buttons = this.element.querySelectorAll('[role="tab"]');
    this.tabPanels = Array.from(
      this.element.querySelectorAll('[role="tabpanel"]')
    );

    // Bind methods
    this.showTabContent = this.showTabContent.bind(this);

    // Initialize the component
    this.init();
  }

  init() {
    // Hide all tab panels except the first one
    this.tabPanels.forEach((panel, index) => {
      panel.hidden = index !== 0;
    });

    // Set the first tab button as selected
    this.buttons.forEach((button, index) => {
      button.setAttribute('aria-selected', index === 0 ? 'true' : 'false');
      // Add event listener
      button.addEventListener('click', this.showTabContent);
    });
  }

  toggleAttribute(elements, attribute, value) {
    elements.forEach((element) => {
      element.setAttribute(attribute, value);
    });
  }

  showTabContent(e) {
    const id = e.currentTarget.id;

    this.toggleAttribute(this.tabPanels, 'hidden', true);
    this.toggleAttribute(this.buttons, 'aria-selected', 'false');
    e.currentTarget.setAttribute('aria-selected', 'true');

    const tabPanel = this.tabPanels.find(
      (panel) => panel.getAttribute('aria-labelledby') === id
    );
    if (tabPanel) {
      tabPanel.hidden = false;
    }
  }
}
