export default class NameAnimation {
  constructor(element) {
    this.element = element;
    this.fred = this.element.querySelector('.fred');
    this.lag = this.element.querySelector('.lag');
    this.init();
  }

  init() {
    this.setupObserver();
  }

  setupObserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.fred.classList.add('is-inview');
            this.lag.classList.add('is-inview');
            observer.unobserve(this.element);
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(this.element);
  }
}
