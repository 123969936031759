// Class definition for the Header
export default class Header {
  constructor(element) {
    this.element = element;
    this.html = document.documentElement;

    this.initNavMobile(); // Initialize mobile navigation functionality
  }

  initNavMobile() {
    const toggle = this.element.querySelector(".js-toggle"); // Find the toggle button
    toggle.addEventListener("click", this.onToggleNav.bind(this)); // Attach click event handler
  }

  onToggleNav() {
    this.html.classList.toggle("nav-is-active"); // Toggle the active class on the HTML element
  }
}
