export default class VideoPlayer {
  constructor(element) {
    this.video = element.querySelector('video');
    this.init();
  }

  init() {
    if (this.video) {
      console.log('VideoPlayer initialized');
      this.video.addEventListener('mouseenter', () => this.playVideo());
      this.video.addEventListener('mouseleave', () => this.pauseVideo());
      this.video.addEventListener('click', () => this.togglePlayPause());
    } else {
      console.error('No video element found');
    }
  }

  playVideo() {
    if (this.video && typeof this.video.play === 'function') {
      this.video
        .play()
        .catch((error) => console.error('Error playing video:', error));
    } else {
      console.error('Play function not available');
    }
  }

  pauseVideo() {
    if (this.video && typeof this.video.pause === 'function') {
      this.video.pause();
    } else {
      console.error('Pause function not available');
    }
  }

  togglePlayPause() {
    if (this.video) {
      if (this.video.paused) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    }
  }
}
