import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class CompetencesAnimation {
  constructor(element) {
    this.element = element;
    this.containers = this.element.querySelectorAll('.competences__container');

    this.init();
  }

  init() {
    this.containers.forEach((container) => {
      const content = container.querySelector('.competences__content');
      const text = container.querySelector('.competences__text');
      const h1 = text.querySelector('h1');
      const h5 = text.querySelector('h5');
      const logiciels = container.querySelector('.competences__logiciels');

      // Set initial state
      gsap.set(container, { backgroundColor: 'var(--color-black)' });
      gsap.set(content, { flexDirection: 'column', alignItems: 'center' });
      gsap.set(text, { textAlign: 'center' });
      gsap.set(h1, { color: 'var(--color-white)', x: 0 });
      gsap.set(h5, { display: 'none', opacity: 0, maxHeight: 0 });
      gsap.set(logiciels, { display: 'none', opacity: 0, x: 20 });

      ScrollTrigger.create({
        trigger: container,
        start: 'top 80%',
        end: 'bottom 20%',
        onEnter: () =>
          this.animateEnter(container, content, text, h1, h5, logiciels),
        onLeave: () =>
          this.animateLeave(container, content, text, h1, h5, logiciels),
        onEnterBack: () =>
          this.animateEnter(container, content, text, h1, h5, logiciels),
        onLeaveBack: () =>
          this.animateLeave(container, content, text, h1, h5, logiciels),
      });
    });
  }

  animateEnter(container, content, text, h1, h5, logiciels) {
    gsap.to(container, {
      backgroundColor: 'var(--color-primary)',
      duration: 0.5,
    });
    gsap.to(content, {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
      duration: 0.5,
    });
    gsap.to(text, { textAlign: 'left', duration: 0.5 });
    gsap.to(h1, { color: 'var(--color-black)', x: -50, duration: 0.5 });
    gsap.to(h5, {
      display: 'block',
      opacity: 1,
      maxHeight: '100px',
      color: 'var(--color-black)',
      duration: 0.5,
      delay: 0.2,
    });
    gsap.to(logiciels, {
      display: 'flex',
      opacity: 1,
      x: 0,
      duration: 0.5,
      delay: 0.2,
    });
  }

  animateLeave(container, content, text, h1, h5, logiciels) {
    gsap.to(container, {
      backgroundColor: 'var(--color-black)',
      duration: 0.5,
    });
    gsap.to(content, {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      duration: 0.5,
    });
    gsap.to(text, { textAlign: 'center', duration: 0.5 });
    gsap.to(h1, { color: 'var(--color-white)', x: 0, duration: 0.5 });
    gsap.to(h5, {
      opacity: 0,
      maxHeight: 0,
      duration: 0.5,
      onComplete: () => gsap.set(h5, { display: 'none' }),
    });
    gsap.to(logiciels, {
      opacity: 0,
      x: 20,
      duration: 0.5,
      onComplete: () => gsap.set(logiciels, { display: 'none' }),
    });
  }
}
